<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col"></div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../layouts/main.vue";
import PageHeader from "@/components/page-header";

export default {
  components: {
    Layout,
    PageHeader,
  },
  mounted() {
    this.$router.push("/debtors");
  },
  data() {
    return {
      title: "Главная",
      items: [],
    };
  },
};
</script>
